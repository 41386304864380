<template>
  <div class="text-muted" id="Footer">
    <div class="container">
      <p class="float-right">
        <a href="#Header" v-smooth-scroll>Back to top</a>
      </p>
      <p>Bartfolio© by Bartollo</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
</style>