export const jobs= [
    {
      client: "Nova Comunicar",
      name: "App Android and IOS",
      description: "",
      project:
        "These apps are built with Angular and Ionic, using Wordpress REST-API and integration with Firebase Message for notification.",
      link: "http://novacomunicar.com.br",
      img: "novacomunicar.png",
      tags: [
        {
          name: "Full Stack Developer",
          color: "complete",
          icon: "mdi-label",
        },
        { name: "Team Manager",
        color: ""},
        {
          name: "Angular",
          color: "complete",
          icon: "mdi-label",
        },
        {
          name: "Bootstrap",
          color: "complete",
          icon: "mdi-label",
        },
        {
          name: "Ionic",
          color: "complete",
          icon: "mdi-label",
        },
        {
          name: "Wordpress",
          color: "complete",
          icon: "mdi-label",
        },
        {
          name: "Mysql",
          color: "complete",
          icon: "mdi-label",
        },
        {
          name: "REST-API",
          color: "complete",
          icon: "mdi-label",
        },
        {
          name: "Firebase",
          color: "complete",
          icon: "mdi-label",
        },
      ],
    },
    {
      client: "Confidential Client",
      name: "Laravel System",
      description: "",
      project:
        "This is a confidential project that is still under development. I've been developing this project using Laravel, Jquery, REST-API and Postgres Database.",
      link: "",
      img: "languageschool.png",
      tags: [
        {
          name: "Full Stack Developer",
          color: "complete",
        },
        { name: "Team Manager",
        color: ""},
        {
          name: "Developing",
          color: "complete",
        },
        {
          name: "Bootstrap",
          color: "complete",
        },
        {
          name: "Jquery",
          color: "complete",
        },
        {
          name: "Bootstrap",
          color: "complete",
        },
        {
          name: "Postgresql",
          color: "complete",
        },
        {
          name: "Laravel",
          color: "complete",
        },
        {
          name: "REST-API",
          color: "complete",
        }
      ],
    },
    
    {
      client: "YES DIGITAL MUSIC",
      name: "Music App IOS",
      description: "YES DIGITAL MUSIC is a project by Matheus Ramos, a renowned Brazilian television announcer and presenter.",
      project: "This streaming music player app was built using Swift, on Xcode, and REST-API.",
      link: "https://www.instagram.com/yesdigitalmusic/",
      img: "yesapp.png",
      tags: [
        {
          name: "Full Stack Developer",
          color: "complete",
        },
        { name: "Team Manager",
        color: ""},
        {
          name: "Swift",
          color: "complete",
        },
        {
          name: "REST-API",
          color: "complete",
        }
      ],
    },
    {
      client: "IMAP",
      name: "App Android and IOS",
      description:
        "IMAP is a company that works with systems to improve government management in Brazilian municipalities.",
      project:
        'This app was built in JAVA. The other side is a REST-API server using Codeigniter and Sql Server. Your goal is to perform a School Transport Tracking in Real Time.',
      link: "http://www.imap.org.br/",
      img: "sigemgps.png",
      tags: [
        {
            name: "Full Stack Developer",
            color: "complete",
            icon: "mdi-label",
          },
        {
          name: "Java",
          color: "complete",
        },
        {
          name: "SQL Server",
          color: "complete",
        },
        {
          name: "CodeIgniter",
          color: "complete",
        },
        {
          name: "Google Maps API",
          color: "complete",
        },
        {
          name: "REST-API",
          color: "complete",
        }
      ],
    },
    {
      client: "IMAP",
      name: "App Android and IOS",
      description:
      "IMAP is a company that works with systems to improve government management in Brazilian municipalities.",
      project:
        "A School Management System for cities public schools in Brazil. This system offer many functions and is used by 12000 students.",
      link: "http://imap.org.br",
      img: "sigem.png",
      tags: [
          { name: "Full Stack Developer",
        color: ""},
        {
          name: "JQuery",
          color: "complete",
        },
        {
            name: "SQL Server",
            color: "complete",
          },
          {
            name: "CodeIgniter",
            color: "complete",
          },
          {
            name: "Google Maps API",
            color: "complete",
          },
          {
            name: "REST-API",
            color: "complete",
          }
      ],
    },
    {
      client: "SENAC",
      name: "App Android and IOS",
      description: "A non-profit Brazilian private institution that makes available Education, Health, Culture and Recreational programs and events and well as provides Social Assistance to workers and their dependents.",
      project:"Automation and improvement of educational system processes in SQL Server databases using T-SQL.",
      link: "https://www.ba.senac.br/",
      img: "senac.png",
      tags: [
        { name: "T-SQL Developer",
        color: ""},
        
        {
          name: "Complete",
          color: "complete",
        },
        {
          name: "Angular",
          color: "complete",
        },
        {
          name: "Bootstrap",
          color: "complete",
        },
        {
          name: "Ionic",
          color: "complete",
        },
        {
          name: "Wordpress",
          color: "complete",
        },
        {
          name: "Mysql",
          color: "complete",
        },
        {
          name: "REST-API",
          color: "complete",
        },
        {
          name: "Firebase",
          color: "complete",
        },
      ],
    },
    {
        client: "SENAC",
        name: "App Android and IOS",
        description: "A non-profit Brazilian private institution that makes available Education, Health, Culture and Recreational programs and events and well as provides Social Assistance to workers and their dependents.",
        project:"Developing and Maintenance education system using JAVA, SQL Server and Maker All Framework.",
        link: "https://www.ba.senac.br/",
        img: "senacsys.png",
        tags: [
            { name: "Full Stack Developer",
            color: ""},
          {
            name: "JAVA",
            color: "complete",
          },
          {
            name: "Sql Server",
            color: "complete",
          },
          {
            name: "Maker All",
            color: "complete",
          }
        ],
      },
    {
      client: "R2 Espaço Inteligente",
      name: "Appointment and Billing Systems",
      description: "R2 Espaço Inteligente is a company that works with rental of commercial rooms",
      project: "This Appointment and Billing Systems was built using Laravel, Jquery and Mysql.",
      link: "http://r2espacointeligente.com.br",
      img: "r2espacointeligente.png",
      tags: [
        { name: "Full Stack Developer",
        color: ""},
        { name: "Team Manager",
        color: ""},
        {
          name: "Complete",
          color: "complete",
        },
        {
          name: "Bootstrap",
          color: "complete",
        },
        {
          name: "Mysql",
          color: "complete",
        },
        {
          name: "Laravel",
          color: "complete",
        },
        {
          name: "JQuery",
          color: "complete",
        },
        {
          name: "REST-API",
          color: "complete",
        },
      ],
    },
    {
      client: "Government of the State of Bahia",
      name: "Social Assistance Management Software",
      description: "Software to serve the government program Bahia Acolhe",
      project: "Social Assistance Management Software was built using Laravel, Jquery and Mysql.",
      link: "http://www.bahia.ba.gov.br/",
      img: "bahiaacolhe.png",
      tags: [
        { name: "Full Stack Developer",
        color: ""},
        { name: "Team Manager",
        color: ""},
        {
            name: "Complete",
            color: "complete",
          },
          {
            name: "Bootstrap",
            color: "complete",
          },
          {
            name: "Mysql",
            color: "complete",
          },
          {
            name: "Laravel",
            color: "complete",
          },
          {
            name: "JQuery",
            color: "complete",
          },
          {
            name: "REST-API",
            color: "complete",
          },
      ],
    },
    {
      client: "Government of the State of Bahia",
      name: "Management system for public departments and state departments ",
      description: "State Secretariat for the Environment of the State of Bahia",
      project:
        "Managment Software was built using php, postgresql and jquery",
      link: "http://www.bahia.ba.gov.br/",
      img: "sema.png",
      tags: [
        { name: "Full Stack Developer",
        color: ""},
        { name: "Team Manager",
        color: ""},
        {
          name: "Complete",
          color: "complete",
        },
        {
          name: "Angular",
          color: "complete",
        },
        {
          name: "Bootstrap",
          color: "complete",
        },
        {
          name: "Ionic",
          color: "complete",
        },
        {
          name: "Wordpress",
          color: "complete",
        },
        {
          name: "Mysql",
          color: "complete",
        },
        {
          name: "REST-API",
          color: "complete",
        },
        {
          name: "Firebase",
          color: "complete",
        },
      ],
    },
    {
        client: "UniFTC",
        name: "University management system",
        description: "Private university based in Salvador, BA, Brazil",
        project:
          "Developing and Maintenance university remote education system using ASP, SQL Server and JS.",
        link: "http://uniftc.edu.br",
        img: "ftc.png",
        tags: [
            {
                name: "Full Stack Developer",
                color: "complete",
                icon: "mdi-label",
              },
          {
            name: "Complete",
            color: "complete",
          },
          {
            name: "Angular",
            color: "complete",
          },
          {
            name: "Bootstrap",
            color: "complete",
          },
          {
            name: "Ionic",
            color: "complete",
          },
          {
            name: "Wordpress",
            color: "complete",
          },
          {
            name: "Mysql",
            color: "complete",
          },
          {
            name: "REST-API",
            color: "complete",
          },
          {
            name: "Firebase",
            color: "complete",
          },
        ],
      },
  ];