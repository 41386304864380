export const journey = [
    {
      from: new Date(2005, 4),
      title:
        'Treinner at <a target="_blank" href="https://www.uniftc.edu.br/">Uniftc University, Ba, Brazil</a>',
      description:
        "Developing Academic Systems with Sql Server, Asp and Php.",
      color: "rgb(204 213 219)",
    },
    {
      from: new Date(2007, 4),
      title:
        'Junior Developer at <a target="_blank" href="https://www.uniftc.edu.br/">Uniftc University, Ba, Brazil</a>',
      description:
        "Developing Academic Systems with Sql Server, Asp and Php.",
      color: "rgb(204 213 219)",
    },
    {
      from: new Date(2008, 4),
      title:
        'Senior Developer at <a target="_blank" href="https://www.uniftc.edu.br/">Uniftc University, Ba, Brazil</a>',
      description:
        "Developing Academic Systems with Sql Server, Asp and Php.",
      color: "rgb(204 213 219)",
    },
    {
      from: new Date(2009, 7),
      title:
        'Bachelor\'s Degree, B. Sc. Information Systems at <a target="_blank" href="http://www.unifacs.br/">UNIFACS, Ba, Brazil</a>',
      description:
        "Article: WEBVOTE: A SERVICE TO SUPPORT THE VOTING PROCESS IN BRAINSTORMING ACTIVITIES IN A VIRTUAL LEARNING ENVIRONMENT.",
      color: "#ffc107",
    },
    {
      from: new Date(2010, 7),
      title:
        'Developer at <a target="_blank" href="https://www.ba.senac.br/">SENAC, Ba, Brazil</a>',
      description:
        "Developing Systems for a public institution focused on teaching with Sql Server, Java, Maker All(R),Php.",
      color: "rgb(204 213 219)",
    },
    {
      from: new Date(2012, 1),
      title: "CEO at Navegart, Ba, Brazil",
      description: "Foundation of systems development company.",
      color: "#4f4db1",
    },

    {
      from: new Date(2013, 7),
      title:
        'Leader Developer Team at <a target="_blank" href="http://www.meioambiente.ba.gov.br/">Bahia State Meio Ambiente Department</a> by <a target="_blank" href="https://www.ctis.com.br/">CTIS, Ba, Brazil</a>',
      description: "Leadering Developer Team.",
      color: "rgb(204 213 219)",
    },
    {
      from: new Date(2014, 7),
      title:
        'Developer at <a target="_blank" href="http://www.imap.org.br/">IMAP, Ba, Brazil</a>',
      description:
        "Developing Systems for Brazilian municipalities and states with Sql Server, Mysql, CodeIgniter, Php, Jquery, Javascript.",
      color: "rgb(204 213 219)",
    },
    {
      from: new Date(2018, 7),
      title:
        'Specialist\'s Degree, Lato Sensu Graduate, Software Development for WEB at <a target="_blank" href="http://www.unifacs.br/">UNIFACS, Ba, Brazil</a>',
      description:
        "Article: MIGRATING A RESPONSIVE WEB APP SYSTEM FOR A WEB PROGRESSIVE APPLICATION.",
      color: "#ffc107",
    },
  ]